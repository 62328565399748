import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
import router from '@/router';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
function createP(data) {
  return storeRequest.post('/products', {
    product: data,
  });
}
function get(params) {
  console.log('params', params);
  return storeRequest.get('/private-apps', {
    params: { ...params },
  });
}
function getPrivateAppId(id) {
  return storeRequest.get('/private-apps/' + id);
}
function createPrivateApp(data) {
  console.log('params', data);
  return storeRequest.post('/private-apps', {
    privateApp: data,
  });
}
function updatePrivateApp(data) {
  console.log('params', data);
  return storeRequest.put('/private-apps/' + data._id, {
    privateApp: data,
  });
}
function deletePrivateApp(id) {
  return storeRequest.delete('/private-apps/' + id);
}

export const privateAppApi = {
  get,
  createPrivateApp,
  getPrivateAppId,
  deletePrivateApp,
  updatePrivateApp,
};
